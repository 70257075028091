(function () {
  'use strict';

  /**
   * Executed when the page is badgegenerator , adds events to the dropdowns.
   *
   * @return void
   */
  $(document).ready(function () {
    if (window.location.pathname == '/badgegenerator') {
      $('#pinSubmit').click(function () {
        if (pin == $('#userPin').val()) {
          displayBadgeGenerator();
        } else {
          $('.error').removeClass('js-hide');
        }
      });
      updateStyle();
      $('#badgeSize').on('change', updateImageSize);
      $('#badgeStyle').on('change', updateStyle);
    }
  });

  /**
   * Executed to display the badge generation section when pin is valid.
   *
   * @return void
   */

  function displayBadgeGenerator() {
    $('#pinSection').addClass('hide');
    $('#badgeSection').removeClass('hide');
  }

  /**
   * Called when the style is changed, Updates the color dropdown accroding to the style selected.
   *
   * @return void
   */
  function updateStyle() {
    var selectedStyle = $('#badgeStyle').val();
    var allStyleColors = $('#badgeColor').children('select');
    if (allStyleColors.length > 0) {
      allStyleColors.addClass('hide');
      for (var i = 0; i < allStyleColors.length; i++) {
        var color = $(allStyleColors[i]);
        if (color.attr('data-stylename') == selectedStyle) {
          color.removeClass('hide');
          color.on({
            change: function () {
              updateImageSrc($(this).val(), $('option:selected', this).attr('data-imageAltText'));
            },
          });
          updateImageSrc(color.val(), $('option:selected', color).attr('data-imageAltText'));
          updateImageSize();
          break;
        }
      }
    }
  }

  /**
   * Updates the image size based on the value selected.
   *
   * @return void
   */
  function updateImageSize() {
    var selectedSize = $('#badgeSize').val();
    switch (selectedSize) {
      case 'small':
        $('#badge').css('width', '139px');
        break;
      case 'medium':
        $('#badge').css('width', '191px');
        break;
      case 'large':
        $('#badge').css('width', '295px');
        break;
      default:
        $('#badge').css('width', '139px');
        break;
    }
    updateCode();
  }

  /**
   * Updates the image source on change of color or style.
   *
   * @param {string} imageName Name of the Image to be displayed attached
   * @return void
   */
  function updateImageSrc(imageName, imageAltText) {
    if (imageName != '') {
      var urlPrefix = 'https://696f2f1b047a974c9d02-fe376ee1391e139016dd683a89931094.ssl.cf1.rackcdn.com/badges/';
      $('#badge').attr('src', urlPrefix + imageName);
      $('#badge').attr('alt', imageAltText);
    } else {
      $('#badge').attr('src', '');
    }
    updateCode();
  }

  /**
   * Updates the code of to be copied by the user for the image.
   *
   * @return void
   */
  function updateCode() {
    var pathStart = "<a href='https://www.healthlabs.com'> ";
    var pathEnd = ' </a>';
    var badgeTag = $('#badge').prop('outerHTML');
    var code = pathStart + badgeTag + pathEnd;
    $('#badgeCode').text(code);
  }
})();
