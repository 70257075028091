function paramsToObject() {
  var object = {},
    hash = window.location.search.substr(1),
    pairs = hash.split('&');
  if (hash) {
    for (var i = 0; i < pairs.length; i++) {
      if (pairs.hasOwnProperty(i)) {
        var pair = pairs[i].split('=');
        var key = decodeURIComponent(pair[0]),
          val = decodeURIComponent(pair[1]),
          arrayIndicator = '[]';
        if (endsWith(key, arrayIndicator)) {
          // An array
          var arrName = key.replace(arrayIndicator, '');
          if (!(arrName in object)) {
            object[arrName] = [];
          }
          object[arrName].push(val);
        } else {
          object[key] = val;
        }
      }
    }
  }
  return object;
}

function endsWith(str, suffix) {
  if (Object.prototype.toString.call(str) !== '[object String]') {
    throw new Error('Invalid parameter for endsWith (not a string)');
  }
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

// Toggle Change Location Content
// http://stackoverflow.com/questions/5584010/jquery-toggle-html-text

$.fn.toggleText = function (t1, t2) {
  if (this.text() == t1) this.text(t2);
  else this.text(t1);
  return this;
};

window.paramsToObject = paramsToObject;

/**
 * Get a cookies value by its name.
 *
 * @param   name name of the cookies value to get.
 * @returns {Promise<string>}
 */
$.fn.getCookie = function (name) {
  return new Promise(function (resolve, reject) {
    if (
      !document.cookie.split('; ').some(function (cookieKeyValue) {
        const cookieKeyValueParts = cookieKeyValue.split('=');
        if (cookieKeyValueParts[0] === name) {
          resolve(cookieKeyValueParts[1]);
          return true;
        }

        return false;
      })
    ) {
      reject();
    }
  });
};
