var googleMapsPromise = $.Deferred();
var labFinderPromise = $.Deferred();

function onGoogleMapsLoaded() {
  googleMapsPromise.resolve();
}

$.when(googleMapsPromise, labFinderPromise).done(function () {
  LabFinder.init();
});
