import 'foundation-sites/js/foundation';
import 'jquery-creditcardvalidator/jquery.creditCardValidator';
import './utilities';
import './date-utils';
import './add-tests';
import './badgeGenerator';
import './initMap';
import './sentry';
import './live-chat-cls-fix';

$(document).ready(async function () {
  // Store the account token
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  await (function () {
    return $.fn
      .getCookie('account_token')
      .then(function (token) {
        window.localStorage.setItem('login-token', token);
      })
      .catch(function () {
        window.localStorage.removeItem('login-token');
      });
  })();

  // Cookies disabled warning pop-up
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  $('#cookie-popup-button').click(function () {
    location.reload();
  });

  if (!window.navigator.cookieEnabled) {
    $('.cookie-popup').css('display', 'block');
  }

  // Sidebar Dropdown
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  $('.has-dropdown').click(function () {
    $('.dropdown').slideToggle();
    $('.has-dropdown > .fa').toggleClass('fa-angle-down fa-angle-up');
  });

  //Format phone number
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  $('#phone, #pharmacy-phone').keyup(function () {
    $(this).val(
      $(this)
        .val()
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
    );
  });

  // Off-Canvas Icon Toggle
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  $('.right-off-canvas-toggle').click(function () {
    $(this).toggleClass('fa-shopping-cart fa-close').find('.off-canvas__cart-total').show();
  });

  $('.left-off-canvas-toggle').click(function () {
    $(this).toggleClass('fa-bars fa-close').find('.off-canvas__cart-total').hide();
  });

  // Scroll Function
  // http://css-tricks.com/snippets/jquery/smooth-scrolling/
  $(function () {
    $('.js__scroll').click(function () {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html,body').animate(
            {
              scrollTop: target.offset().top,
            },
            800
          );
          return false;
        }
      }
    });
  });

  /**
   * Waits for livechat to load and then lowers its z-index so it appears behind dropdown menus.
   */
  var waitForLiveChatLoaded = function () {
    var liveChatContainer = $('#livechat-compact-container');
    if (liveChatContainer.length > 0) {
      liveChatContainer.css('z-index', '5');
    } else {
      window.setTimeout(waitForLiveChatLoaded, 5000);
    }
  };
  $(waitForLiveChatLoaded);
});

// My Account
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$('.message__list__item').click(function () {
  var messageID = $(this).attr('data-message_id');
  $('.message__list__item').removeClass('js-active');
  $(this).addClass('js-active');
  $('.message').hide();
  $('#' + messageID).show();
});

$(document).foundation({
  accordion: {
    multi_expand: true,
  },
});
